<template>
  <div class="flex flex-col items-center w-full">
    <div class="flex flex-col items-center w-full max-w-6xl mb-4 px-6">
      <LoadingSpinner v-if="loading" />
      <template v-else>
        <div class="flex items-center w-full mb-6">
          <h1 class="text-4xl font-black">Investors</h1>
          <button class="text-green-600 ml-auto" @click.prevent="attemptExportCSV()">
            Export CSV
          </button>
          <span class="mx-6">|</span>
          <button class="text-green-600" @click.prevent="attemptCreateGoogleSpreadsheet()">
            View in Sheets
          </button>
        </div>
        <ContactIndexQueryResultsTable :contacts="contacts.list" />
      </template>
      <div class="flex justify-center" :class="{ hidden: loading }">
        <CollectionPagination
          :collection="contacts"
          pluralItemName="contacts"
          @is-loading-status-update="(isLoading) => (loading = isLoading)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import LoadingSpinner from '@/components/LoadingSpinner'
import Contact from '@/services/contacts'

import ContactIndexQueryResultsTable from '@/components/Query/ContactIndexQueryResultsTable'
import CollectionPagination from '@/components/inputs/CollectionPagination'
import { trackHeapEvent } from '@/services/metrics'

export default {
  name: 'InvestorsIndex',
  components: {
    LoadingSpinner,
    ContactIndexQueryResultsTable,
    CollectionPagination,
  },
  data() {
    return {
      loading: true,
      contacts: Contact.createCollection({
        pagination: {
          size: this.$route.query.pageSize || 20,
          page: this.$route.query.page || 1,
        },
        filters: {
          hasPitchbookPersonId: true,
        },
      }),
    }
  },
  async created() {
    await this.contacts.refresh()
    this.loading = false
  },
  methods: {
    setPaginationQueryParams() {
      this.$router.push({
        name: this.$route.name,
        query: {
          page: this.contacts.pagination.page,
          pageSize: this.contacts.pagination.size,
        },
      })
    },
    attemptExportCSV() {
      trackHeapEvent('Exported Basic Investors CSV', {
        userId: this.$store.state.user.id,
        userEmail: this.$store.state.user.email,
      })
      this.$store
        .dispatch('setLoading', true)
        .then(Contact.api.getInvestorsCSV)
        .then((csv) => {
          const url = window.URL.createObjectURL(new Blob([csv]))
          // Construct the file name
          const now = new Date()
          const dateString = now.toISOString().split('T')[0] // Format: YYYY-MM-DD
          const fileName = `investors-${dateString}.csv`
          // Download the file
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', fileName)
          link.click()
        })
        .finally(() => this.$store.dispatch('setLoading', false))
    },
    attemptCreateGoogleSpreadsheet() {
      trackHeapEvent('Viewed Basic Investors in Google Sheets', {
        userId: this.$store.state.user.id,
        userEmail: this.$store.state.user.email,
      })
      this.$store
        .dispatch('setLoading', true)
        .then(Contact.api.getInvestorsGoogleSpreadsheet)
        .then(({ spreadsheetUrl }) => {
          const link = document.createElement('a')
          link.href = spreadsheetUrl
          link.target = '_blank'
          link.click()
        })
        .finally(() => this.$store.dispatch('setLoading', false))
    },
  },
  watch: {
    'contacts.pagination.page': {
      immediate: true,
      deep: true,
      handler() {
        this.setPaginationQueryParams()
      },
    },
    'contacts.pagination.size': {
      immediate: true,
      deep: true,
      handler() {
        this.setPaginationQueryParams()
      },
    },
  },
}
</script>
