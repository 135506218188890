<template>
  <div class="relative overflow-x-auto shadow-md sm:rounded-lg max-w-full">
    <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
      <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
        <tr>
          <th scope="col" class="px-6 py-3"></th>
          <th scope="col" class="px-6 py-3">Name</th>
          <th scope="col" class="px-6 py-3">Title</th>
          <th scope="col" class="px-6 py-3">Company</th>
          <th scope="col" class="px-6 py-3">Community Types</th>
          <th scope="col" class="px-6 py-3">Closest Contacts</th>
          <th scope="col" class="px-6 py-3">
            <LabelWithTooltip
              label="Warmth"
              forTableHeader
              help="Warmth is a measure of closeness to FRC, based on a combination of time since last inbound / outbound emails with us, engagements, deals referred, events attended, intros, and if they have worked in the community."
            />
          </th>
          <th scope="col" class="px-6 py-3">Last Outbound Email from FRC</th>
          <th scope="col" class="px-6 py-3">Last Inbound Email to FRC</th>
          <th scope="col" class="px-6 py-3">Salesforce URL</th>
          <th scope="col" class="px-6 py-3">About(LinkedIn)</th>
          <th scope="col" class="px-6 py-3">Location</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="c in contacts"
          :key="c.id"
          class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
        >
          <td class="px-6 py-4">
            <Popper arrow hover placement="bottom-end">
              <div class="flex items-center justify-center p-1">
                <button class="h-5 w-5 mr-5">
                  <img
                    :src="require('@/assets/thumb_up.svg')"
                    alt="positive feedback"
                    class="positive-feedback-button-contact"
                    @click.prevent="$emit('feedback', c, 1)"
                  />
                </button>
                <button class="h-5 w-5">
                  <img
                    :src="require('@/assets/thumb_down.svg')"
                    alt="negative feedback"
                    class="negative-feedback-button-contact"
                    @click.prevent="$emit('feedback', c, -1)"
                  />
                </button>
              </div>
              <template #content>
                <div class="bg-white box-border py-5 px-10 border rounded-lg drop-shadow-lg">
                  Provide feedback to help train a future AI Model.
                </div>
              </template>
            </Popper>
          </td>
          <th
            scope="row"
            class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
          >
            <a
              v-if="c.linkedinUrl"
              :href="c.linkedinUrl"
              target="_blank"
              class="text-blue-600 dark:text-blue-500 hover:underline contact-linkedin-link"
              >{{ c.firstName }} {{ c.lastName }}</a
            >
            <template v-else> {{ c.firstName }} {{ c.lastName }} </template>
          </th>
          <td class="px-6 py-4">{{ c.title }}</td>
          <td class="px-6 py-4">{{ c.companyName }}</td>
          <td class="px-6 py-4">{{ c.communityTypesList.join(', ') }}</td>
          <td class="px-6 py-4">{{ c.sigparserMailboxesList.join(', ') }}</td>
          <td class="px-6 py-4">{{ c.warmth }}</td>
          <td class="px-6 py-4">
            {{
              c.sigparserLastOutbound !== null && c.sigparserLastOutbound !== ''
                ? moment(c.sigparserLastOutbound).format('MM/DD/YYYY')
                : 'N/A'
            }}
          </td>
          <td class="px-6 py-4">
            {{
              c.sigparserLastInbound !== null && c.sigparserLastInbound !== ''
                ? moment(c.sigparserLastInbound).format('MM/DD/YYYY')
                : 'N/A'
            }}
          </td>
          <td class="px-6 py-4">
            <a
              :href="c.salesforceUrl"
              target="_blank"
              class="text-blue-600 dark:text-blue-500 hover:underline contact-salesforce-link"
              >{{ c.salesforceUrl }}</a
            >
          </td>
          <td class="px-6 py-4">
            <div class="scrollable-cell">
              {{ c.aboutLinkedin }}
            </div>
          </td>
          <td class="px-6 py-4">{{ c.location }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import moment from 'moment'
import LabelWithTooltip from '@/components/inputs/LabelWithTooltip'
export default {
  name: 'ContactIndexQueryResultsTable',
  components: { LabelWithTooltip },
  data() {
    return {
      moment,
    }
  },
  props: {
    contacts: {
      type: Array,
      required: true,
    },
  },
}
</script>
