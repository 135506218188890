<template>
  <label :for="`${label}-field`" :class="labelClasses">
    {{ label }}<span v-if="required" class="text-primary">*</span>
    <Popper v-if="help" arrow :placement="helpPlacement">
      <InformationCircleIcon :class="`h-5 w-5 cursor-pointer ml-2 tooltip-${label}`" />
      <template #content>
        <div class="popup-content font-normal normal-case" v-html="help" />
      </template>
    </Popper>
  </label>
</template>

<script>
import { InformationCircleIcon } from '@heroicons/vue/24/solid'

export default {
  name: 'LabelWithTooltip',
  components: { InformationCircleIcon },
  props: {
    label: {
      type: String,
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    help: {
      type: String,
    },
    helpPlacement: {
      type: String,
      default: 'right-end',
    },
    forTableHeader: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    labelClasses() {
      return {
        'font-bold mb-1 flex items-center': true,
        'mt-8': !this.forTableHeader,
      }
    },
  },
}
</script>

<style scoped>
.popup-content {
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 0.5rem;
  padding: 0.5rem;
  font-size: 0.875rem;
  max-width: 16rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
  word-wrap: break-word; /* Ensure long words break to the next line */
}

/* Mobile styles */
@media (max-width: 768px) {
  .popup-content {
    max-width: 12rem;
    padding: 0.75rem;
    font-size: 1rem;
    border: 1px solid #ddd; /* Retain border styles */
    border-radius: 0.5rem; /* Retain border radius */
  }
}
</style>
